import { Model, Store, Casts, api } from 'store/Base';
import { computed, observable } from 'mobx';
import { Location } from './Location';
import { TripStore } from './Trip';
import { TripActivitiesStore } from './TripActivities';
import { Job } from './Job';
import ACTIVITY_TYPES, { LOAD } from 'store/enums/ActivityType';
import DRAFT, { ACTIVITY_STATUSES, getActivityStatusColor } from 'store/enums/ActivityStatus';
import ACTIVITY_UNITS from 'store/enums/ActivityUnit';
import { BookingStore } from './Booking';
import { DateTime } from 'luxon';
import { Customs } from 'store/Customs';
import BOOKING_STATUS from './enums/BookingStatus';
import ActivityType from 'store/enums/ActivityType';
import { DATETIME_FORMAT_SHORT, DATE_FORMAT, formatDatetime } from 'helpers';
import { camelCase, omit } from 'lodash';
import { DocumentStore } from './Document';
import TripType from './enums/TripType';
import { ChangeStore } from './Change';
import { VirtualizedStore } from './VirtualizedStore';
import { Trailer } from './Trailer';
import { Truck } from './Truck';
import { TYPE_SALES } from './enums/TripType';
import { Service } from './Service';
import { TaskStore } from './Task';
import { DriverActivitySyncStatus } from 'react-logistics-driverapp/src/store/DriverActivitySyncStatus';

export function getStatusAt(status) {
    return camelCase(`status ${status} at`);
}

export class Activity extends Model {
    static backendResourceName = 'activity';

    @observable id = null;

    @observable status = DRAFT;

    @observable type = ACTIVITY_TYPES.LOAD;
    @observable unit = ACTIVITY_UNITS.FTL;
    @observable unNumber = null;
    @observable reference = null;
    @observable goodsDescription = null;
    @observable instructions = null;
    @observable remarks = null;
    @observable companyName = null;

    @observable orderedArrivalDatetimeFrom = null;
    @observable orderedArrivalDatetimeUntil = null;

    @observable startKm = null;
    @observable endKm = null;

    @observable sentTo = null;
    @observable sentAt = null;
    @observable sentTo = null;
    @observable acceptedAt = null;
    @observable rejectedAt = null;

    @observable invoiceableKms = 0;

    // Keeps track of last time status was changed to a specific status. I'd
    // like to map over ACTIVITY_STATUSES and add @observable, but no idea how
    // that works. Maybe:
    // https://doc.ebichu.cc/mobx/refguide/extend-observable.html
    @observable statusArrivedAt = null;
    @observable statusCanceledAt = null;
    @observable statusDraftAt = null;
    @observable statusEtaAt = null;
    @observable statusFinalizedAt = null;
    @observable statusFinishedAt = null;
    @observable statusPlannedAt = null;
    @observable statusStartedAt = null;
    @observable statusWaitingAt = null;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable hasTasks = false;
    @observable hasProofOfDelivery = false;

    // computed field on backend
    @observable allowedNextStatuses = [];
    @observable weekNo = null;
    @observable yearNo = null;

    @observable lastTrackedChangeConfirmedAt = null;

    @observable isNotExecuting = false;
    @observable isLastOrderActivity = null;

    @observable trailerId = null;
    @observable truckId = null;

    @computed get drivenKm() {
        return (this.endKm || 0) - (this.startKm || 0);
    }

    typeToString() {
        switch (this.type) {
            case ActivityType.LOAD:
                return t('activity.types.loadAction');
            case ActivityType.UNLOAD:
                return t('activity.types.unloadAction');
            case ActivityType.TERMINAL_DROP:
                return t('activity.types.terminalDropAction');
            case ActivityType.TERMINAL_PICK_UP:
                return t('activity.types.terminalPickUpAction');
            case ActivityType.YARD_DROP:
                return t('activity.types.yardDropAction');
            case ActivityType.YARD_PICK_UP:
                return t('activity.types.yardPickUpAction');
            case ActivityType.CUSTOMER_ACTIVITY:
                return t('activity.types.customerActivityAction');
            case ActivityType.DALESSI_ACTIVITY:
                return t('activity.types.dalessiActivityAction');
            case ActivityType.TRUCK_PICK_UP:
                return t('activity.types.truckPickUpAction');
            case ActivityType.TRUCK_SERVICE:
                return t('activity.types.truckServiceAction');
            case ActivityType.TRAILER_SERVICE:
                return t('activity.types.trailerServiceAction');
            default:
                return ''
        }
    }

    @computed get tagLabel() {
        return `A${this.id}`;
    }

    @computed get editUrl() {
        return '';
    }

    @computed get bookings() {
        if (this.fromBookings != null && this.toBookings != null) {
            return [...this.fromBookings?.models, ...this.toBookings?.models];
        }
        return null;
    }

    @computed get isTerminal() {
        return this.type === ActivityType.TERMINAL_DROP || this.type === ActivityType.TERMINAL_PICK_UP;
    }

    @computed get hasBooking() {
        return this.bookings?.length > 0;
    }

    @computed get lastBooking() {
        if (!this.hasBooking) {
            return null;
        }
        // return this.bookings[this.bookings.length - 1];
        return this.bookings[0];
    }

    @computed get isBooked() {
        return this.lastBooking?.status === BOOKING_STATUS.BOOKED;
    }

    @computed get isPlanned() {
        return this.lastBooking?.status !== BOOKING_STATUS.DRAFT;
    }




    /**
     * Save `status` to backend.
     */
    saveStatus(status, skipValidation) {
        return this.wrapPendingRequestCount(
            this.api.put(this.url + 'status/', { status, skipValidation })
        );
    }

    getInstructions(trip) {
        let terminalName = '';
        let shippingReference = this.reference;
        let companyName = this.companyName !== '' && this.companyName !== null ? `${t('job.field.companyName.label')}: ${this.companyName}\n` : '';
        let pinCode = '';
        if (this.hasBooking) {
            terminalName = this.lastBooking.schedule.route.fromTerminal.name;
            shippingReference = this.lastBooking.shippingReference;
            pinCode = this.lastBooking.pinCode;
            companyName = `${t('job.field.companyName.label')}: ${this.lastBooking.schedule.route.transporter.name}\n`;
        }

        let result = (
            `${this.typeToString()} \n` +
            `${companyName}` +
            `${t('job.field.location.label')}: ${terminalName} ${this.location?.address?.toString()}\n` +
            `${t('order.overview.fleetNumbers')}: ${trip.trailer.fleetNumber}\n` +
            `${t('job.field.reference.label')}: ${(shippingReference ? shippingReference : 'No')}${(pinCode ? ' / ' + pinCode : '')}\n` +
            `${t('activity.field.instructions.label')}: ${(this.instructions ? this.instructions : 'No')}\n` +
            `${t('activity.field.orderedArrivalDatetimeFrom.label')}: ${this.orderedArrivalDatetimeFrom?.toFormat(DATETIME_FORMAT_SHORT) ?? ''} - ${this.orderedArrivalDatetimeUntil?.toFormat(DATETIME_FORMAT_SHORT) ?? ''}\n` +
            `${t('order.field.unNumber.label')}: ${(this.job.order.unNumber ? 'Yes' : 'No')}\n`
        );

        if (this.type === LOAD) {
            result += (

                `${t('order.field.packagingAmount.label')}: ${(this.job.order.packagingAmount || '-')}\n` +
                `${t('order.field.packagingType.label')}: ${(this.job.order.packagingType ?? '-')}\n` +
                `${t('order.field.weight.label')}: ${(this.job.order.weight ?? '-')}\n` +
                `${t('order.field.goodsDescription.label')}: ${(this.job.order.goodsDescription || '-')}\n` +
                `${t('order.field.destination.label')}: ${(trip?.getLastActivity()?.location?.address || '-')}\n`
            )
        }

        return result;
    }

    getOrder() {
        return this.job.order;
    }

    attachActivityToTruck(activity, prevActivity, trip, assetType) {
        return api.post(
            '/activity/attach-activity-to-truck/',
            { activity: activity.toJS(), prevActivity: prevActivity.id, trip: trip.id, assetType: assetType }
        );
    }

    get bookingStatusColor() {
        return this.lastBooking?.statusColor ?? 'var(--gray-100)';
    }
    get bookingNotReady() {
        return 'var(--red-600)';
    }

    get bookingStatus() {
        return this.lastBooking?.status;
    }

    get bookingDateTime() {
        return this.formatDatetime(this.lastBooking?.updatedAt, DATETIME_FORMAT_SHORT)
    }

    get statusColor() {
        return getActivityStatusColor(this.status);
    }

    get customsStatusColor() {
        return this?.customs?.calculatedStatus === 'not_ready' ? this.bookingNotReady : this.bookingStatusColor
    }

    get formattedOrderedArrivalDatetimeFrom() {
        return this.formatDatetime(this.orderedArrivalDatetimeFrom)
    }

    get formattedOrderedArrivalDatetimeFromLong() {
        return this.formatDatetime(this.orderedArrivalDatetimeFrom, DATETIME_FORMAT_SHORT)
    }

    get formattedStatusFinishedAt() {
        return this.formatDatetime(this.statusFinishedAt, DATETIME_FORMAT_SHORT)
    }

    get formattedOrderedArrivalDatetimeUntilLong() {
        return this.formatDatetime(this.orderedArrivalDatetimeUntil, DATETIME_FORMAT_SHORT)
    }

    removeYardTerminalActivity(activityId) {
        return this.wrapPendingRequestCount(
            this.api.put(this.url + 'remove-yard-terminal-activity/', { activityId })
        );
    }

    formatDatetime(date, format) {
        if (!this.isPlanned) {
            return null;
        }

        return formatDatetime(date, format);
    }

    getExceptions() {
        const orderActivities = this.getOrder()?.getActivities(TYPE_SALES).reverse();

        if (orderActivities == null) {
            return {
                lateUnloadActivitiesNumber: 0,
                totalUnloadsNumber: 0,
                showExceptions: false,
            }
        }

        const totalUnloadsNumber =  orderActivities.filter(act => act.type === 'unload').length;
        const lateUnloadActivitiesNumber = orderActivities
        .filter(act => act.type === 'unload' && act.orderedArrivalDatetimeFrom?.c.hour >= 10 && act.orderedArrivalDatetimeFrom?.c.minute >= 0).length;
        const multipleUnloads =  orderActivities.filter(act => act.type === 'unload').length > 1;
        return {
            lateUnloadActivitiesNumber,
            totalUnloadsNumber,
            showExceptions: multipleUnloads || lateUnloadActivitiesNumber > 0,
        }

    }

    relations() {
        return {
            location: Location,
            truck: Truck,
            trailer: Trailer,
            trips: TripStore,
            m2mTrips: TripActivitiesStore,
            fromBookings: BookingStore,
            toBookings: BookingStore,
            job: Job,
            documents: DocumentStore,
            customs: Customs,
            lastTrackedChanges: ChangeStore,
            precedingCustoms: Customs,
            service: Service,
            tasks: TaskStore,
            activitySyncStatus: DriverActivitySyncStatus,
        };
    }

    casts() {
        return {
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
            sentAt: Casts.datetime,
            acceptedAt: Casts.datetime,
            rejectedAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            lastTrackedChangeConfirmedAt: Casts.datetime,

            ...ACTIVITY_STATUSES.reduce((obj, status) =>
                ({ ...obj, [getStatusAt(status)]: Casts.datetime }),
                {}
            ),
        };
    }
    toBackend(...args) {
        const data = super.toBackend(...args);

        return omit(data, 'last_tracked_changes', 'preceding_customs', 'is_not_executing', 'is_last_order_activity', 'truck_id', 'trailer_id', 'week_no', 'year_no');
    }

    getTruck() {
        return this.getParentTrip(TripType.TYPE_TRUCK)?.truck;
    }

    getTrailer() {
        return this.getParentTrip(TripType.TYPE_TRAILER)?.trailer;
    }

    getParentTrip(type) {
        return this.m2mTrips?.find(tripActivity => tripActivity.trip.type === type)?.trip;
    }

    getTripActivity() {
        return this.m2mTrips?.find(tripActivity => tripActivity.id === this.id);
    }

    getLastTrackedChanges() {
        if (this.lastTrackedChanges == null || this.lastTrackedChanges.models?.length === 0) {
            return null;
        }

        return this.lastTrackedChanges.models?.filter(element => {
            if (element.before !== null && element.before !== 'null') {
                if (element.field === 'ordered_arrival_datetime_from' && element.after !== null) {
                    const changedDateFrom = DateTime.fromISO(element.before.replaceAll('"', '')).toFormat(DATE_FORMAT);
                    const changedDateTo = DateTime.fromISO(element.after.replaceAll('"', '')).toFormat(DATE_FORMAT);

                    return changedDateTo !== changedDateFrom;
                }

                return true;
            }

            return false;
        });
    }
}

export class ActivityStore extends Store {
    Model = Activity;
    static backendResourceName = 'activity';
}

export class VirtualizedActivityStore extends VirtualizedStore {
    Model = Activity;
    static backendResourceName = 'activity';
}